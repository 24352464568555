import { makeStyles, Dialog, DialogContent, DialogContentText, DialogTitle } from "@material-ui/core";
import clsx from "clsx";
import React, { useEffect } from "react";
import { useTranslation, withTranslation } from "react-i18next";
import { COLORS } from "../../../constant/colors";
import { calculateScores } from "../../../util/calculator";
import DepressionIconSvg from "../../../assets/eTriage/result/newEtriageWithPopup/depressionIcon.svg";
import AnxietyIconSvg from "../../../assets/eTriage/result/newEtriageWithPopup/anxietyIcon.svg";
import SocialImpairmentIconSvg from "../../../assets/eTriage/result/newEtriageWithPopup/socialImpairmentIcon.svg";

import backgroundCloudSvg from "../../../assets/eTriage/result/newEtriageWithPopup/backgroundCloud.svg";
import TooltipSvg from "../../../assets/eTriage/result/tooltip.svg";

import CategoryResultItemComponent from "./components/CategoryResultItemComponent";

import ReactHtmlParser from "react-html-parser";

import { getTriageResult } from "../../../constant/etriage/etriage";
import TagManager from "react-gtm-module";
import koaHealthLogoDark from "../../../assets/koaHealth/koaHealth_row_logo.svg";
import asyncComponent from "./components/asyncComponent";
import Questions from "../../../constant/etriage/questions";

import DialogCloseButtonRow from "../../components/DialogCloseButtonRow";
import TransitionButton from "../../components/core-elements/TransitionButton";
import Spacer from "../../components/core-elements/Spacer";
import AppIcon from "../../components/core-elements/AppIcon";
import oneClickSvg from "../../../assets/general/one-click.svg";
import Button, { buttonRadiusTypes, buttonTypes } from "../../components/core-elements/Button";
import env from "../../../env";
import CommonIcon, { commonIconTypes } from "../../components/core-elements/CommonIcon";
import CategoryResultItemLabelComponent from "./components/CategoryResultItemLabelComponent";

const useStyles = makeStyles((theme) => ({
  pageContainer: {
    flex: 1,
    background: COLORS.fwdBackgroundGrey,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  headerbanner: {
    height: 75,
    width: "100%",
  },
  scoreContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "100%",
    maxWidth: 1064,
    marginTop: 20,
  },
  resultTitle: {
    color: COLORS.fwdDarkGreen,
    fontSize: 25,
    fontWeight: 700,
    lineHeight: "31px",
    textAlign: "center",
    marginBottom: 15,
  },

  resultCategoryLabelContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    width: "100%",
    padding: "15px 5px",
  },

  resultCategoryContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    width: "100%",
    padding: "0px 5px",
  },

  resultAnalysis: {
    background: COLORS.fwdGrey,
    flexGrow: 1,
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
  },
  resultAnalysisContentContainer: {
    width: "100%",
    maxWidth: 1064,
    margin: "20px 16px",
  },
  resultAnalysisTitle: {
    color: COLORS.fwdDarkGreen,
    fontSize: 20,
    fontWeight: 700,
    lineHeight: "25px",
    marginBottom: 10,
  },
  resultAnalysisDescription: {
    color: COLORS.fwdDarkGreen,
    fontSize: 16,
    fontWeight: 450,
    lineHeight: "24px",
    textAlign: "center",
    maxWidth: 700,
    marginBottom: 15,
  },
  nextstepSection: {
    paddingBottom: 150,
  },
  nextstepSectionWithSmallPadding: {
    paddingBottom: 50,
  },
  nextstepCardContainer: {
    display: "flex",
    flexDirection: "column",

    [theme.breakpoints.up("md")]: {
      flexDirection: "row",
      flexWrap: "wrap",
      justifyContent: "space-between",
    },
  },
  letsTalkContainer: {
    position: "absolute",
    bottom: 20,
    right: 30,
  },
  buttonGroupContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "stretch",
  },
  buttonContainer: {
    lineHeight: "18px",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    width: "100%",
    maxWidth: 1064,
    margin: 10,
    justifyContent: "center",
  },
  takeTheTestAgainButton: {
    color: COLORS.fwdOrange,
    fontSize: 16,
    fontWeight: 700,
    lineHeight: "20px",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    cursor: "pointer",
    marginBottom: 20,
  },
  aboutQuestionnaireButton: {
    color: COLORS.fwdOrange,
    fontSize: 16,
    fontWeight: 700,
    lineHeight: "20px",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    cursor: "pointer",
    padding: "15px 30px",
    border: `2px solid ${COLORS.fwdOrange}`,
    borderRadius: 8,
    background: COLORS.white,
    width: "100%",
  },
  backgroundLandscape: {
    width: "100%",
  },
  withEmergency: {
    borderRadius: 0,
  },
  upperSectionContainer: {
    maxWidth: 1064,
    width: "100%",
    padding: "0px 16px",
  },
  upperSectionContainerWithBackground: {
    backgroundImage: `url(${backgroundCloudSvg})`,
    backgroundRepeat: "no-repeat",
    backgroundPositionY: 25,
    backgroundPositionX: "center",
    backgroundSize: "100%",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  poweredBy: {
    display: "flex",
    flexDirection: "row",
    color: COLORS.fwdDarkGreen,
    fontSize: 14,
    fontWeight: 450,
    lineHeight: "21px",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    maxWidth: 1064,
    padding: "35px 20px 20px 20px",
  },
  koaHealthLogo: {
    height: 40,
    marginLeft: 5,
    // cursor: "pointer",
  },
  koaHealthLogoImg: {
    height: "100%",
  },
  tooltipIcon: {
    width: 24,
    height: 24,
    marginLeft: 5,
    cursor: "pointer",
  },
  onClickIcon: {
    width: 20,
    height: 20,
    marginRight: 7,
  },
  saveResultInstruction: {
    color: COLORS.fwdDarkGreen,
    fontSize: 16,
    fontWeight: 450,
    lineHeight: "24px",
  },
  suggestedNextStep: {
    color: COLORS.fwdDarkGreen,
    fontSize: 31,
    fontWeight: 700,
    lineHeight: "39px",
    padding: "10px 0px",
  },
  dialogContent: {
    color: COLORS.fwdDarkGreen,
  },
}));

function ResultPage({ answers, i18n, retakeTest, surveyKey, clientId }) {
  const classes = useStyles();
  const { t } = useTranslation(["translation", "resultPageLongText"]);
  const [scores, setScores] = React.useState(null);
  const [open, setOpen] = React.useState(false);
  const [scroll] = React.useState("paper");
  const [openPoweredByDialog, setOpenPoweredByDialog] = React.useState(false);
  const [shouldShowEmergency, setShouldShowEmergency] = React.useState(false);
  const [shouldDisplaySuggestedProduct, setshouldDisplaySuggestedProduct] = React.useState(false);
  const customerType = clientId ? "customer" : "non-customer";
  const currentBu = process.env.REACT_APP_BU ?? "hk";

  const optionArrayFile = require(`../../../constant/etriage/resultTreatmentOptionArray_${currentBu}`);
  const optionArray = optionArrayFile.default;

  const handleClickOpenPoweredByDialog = () => () => {
    setOpenPoweredByDialog(true);
  };

  const handleClosePoweredByDialog = () => {
    setOpenPoweredByDialog(false);
  };

  const handleClickOpen = () => () => {
    TagManager.dataLayer({
      dataLayer: {
        event: "buttonClick",
        buttonTitle: "about the questionnaire and your results",
        pageUrl: window.location.href,
        componentName: "result page link",
        language: i18n.language,
        destinationURL: window.location.origin,
        customer_type: customerType,
      },
    });
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const takeNewTest = () => {
    const tagTakeTheTestAgainArgs = {
      dataLayer: {
        event: "buttonClick",
        buttonTitle: "take the test again",
        pageUrl: window.location.href,
        componentName: "result page link",
        language: i18n.language,
        destinationURL: window.location.origin,
        customer_type: customerType,
      },
    };

    TagManager.dataLayer(tagTakeTheTestAgainArgs);
    retakeTest();
  };

  const saveResultLinkOnClick = () => {
    const resultPageUrl =
      process.env.REACT_APP_NEW_ETRIAGE_LANDING_WITH_POPUP !== "true"
        ? `${window.location.host}/${i18n.language}/eTriage/result/${surveyKey}`
        : `${window.location.host}/${i18n.language}/result/${surveyKey}`;
    navigator.clipboard.writeText(resultPageUrl);
    const tagSaveResultArgs = {
      dataLayer: {
        event: "buttonClick",
        buttonTitle: "save your result",
        pageUrl: window.location.href,
        componentName: "result page link",
        language: i18n.language,
        destinationURL: window.location.origin,
        customer_type: customerType,
      },
    };

    TagManager.dataLayer(tagSaveResultArgs);
  };

  if (scores == null) {
    setScores(calculateScores(answers));
  }

  const getSymptomsAnalisysResult = (scores) => {
    const depression = t(scores.get("depression")?.level.description);
    const anxiety = t(scores.get("anxiety")?.level.description);
    const impairment = t(scores.get("impairment")?.level.description);
    return `${t("eTriageResultPage.description.yourResultSuggestThat")}${depression}${t("eTriageResultPage.description.comma")}${anxiety}${t(
      "eTriageResultPage.description.comma"
    )}${impairment}${t("eTriageResultPage.description.fullStop")}`;
  };

  const getTreatmentSignposting = (scores) => {
    const depression = scores.get("depression")?.score;
    const anxiety = scores.get("anxiety")?.score;
    const impairment = scores.get("impairment")?.score;
    const result = getTriageResult(depression, anxiety, impairment);

    Questions.forEach((item, index) => {
      if (item.checkEmergencyBanner && answers[index] > 0) {
        setShouldShowEmergency(true);
      }
    });

    return result["Signposting"];
  };

  const ga_symptomsLevelLabel = ["noRisk", "mild", "moderate", "severe"];

  const getSymptomsLevel = (category) => {
    return scores.get(category).level.levelScore;
  };

  const resultCategoryList = [
    {
      category: "depression",
      icon: DepressionIconSvg,
      learnMore: true,
    },
    { category: "anxiety", icon: AnxietyIconSvg, learnMore: true },
    { category: "impairment", icon: SocialImpairmentIconSvg },
  ];

  useEffect(() => {
    //Runs only on the first render
    const tagResultEventArgs = {
      dataLayer: {
        event: "e_triage",
        eTriageSection: "results",
        depressionScore: ga_symptomsLevelLabel[getSymptomsLevel("depression")],
        anxietyScore: ga_symptomsLevelLabel[getSymptomsLevel("anxiety")],
        socialImpairmentScore: ga_symptomsLevelLabel[getSymptomsLevel("impairment")],
        pageUrl: window.location.href,
        language: i18n.language,
        customer_type: customerType,
      },
    };

    TagManager.dataLayer(tagResultEventArgs);

    setshouldDisplaySuggestedProduct(
      process.env.REACT_APP_BU === "hk" && (getTreatmentSignposting(scores).label === 5 || getTreatmentSignposting(scores).label === 6)
    );
  }, [scores]);

  const RenderEmergency = asyncComponent(() => {
    return import(`./components/nextStepOptions/${currentBu}/EmergencyLevelCallNowOption`);
  });
  const RenderNextStepOptions = asyncComponent(() => {
    return import(`./components/nextStepOptions/${currentBu}/OptionsRenderComponent`);
  });

  const RenderProductSuggestion = asyncComponent(() => {
    return import(`./components/nextStepOptions/${currentBu}/ProductsSuggestionContainer`);
  });

  return (
    <>
      {scores != null && (
        <div component="section" className={classes.pageContainer}>
          {shouldShowEmergency ? <RenderEmergency customerType={customerType} /> : <div className={classes.headerbanner}></div>}
          <div className={classes.upperSectionContainer}>
            <div className={classes.upperSectionContainerWithBackground}>
              <div className={classes.poweredBy}>
                {t("poweredBySection.poweredBy")}
                <div
                  className={classes.koaHealthLogo}
                // onClick={handleClickOpenPoweredByDialog()}
                >
                  <img src={koaHealthLogoDark} className={classes.koaHealthLogoImg} />
                </div>
                {t("poweredBySection.poweredBy_2")}
                <div className={classes.tooltipIcon} onClick={handleClickOpenPoweredByDialog()}>
                  <img src={TooltipSvg} className={classes.tooltipIcon} />
                </div>
              </div>
              <div className={classes.scoreContainer}>
                <div className={classes.resultTitle}>{t("eTriageResultPage.yourResults")}</div>
                {/* remove result page description in HHMH-456*/}
                {/* <div className={classes.resultAnalysisDescription}>{getSymptomsAnalisysResult(scores)}</div>  */}
                <div className={classes.resultCategoryLabelContainer}>
                  {resultCategoryList.map((item, index) => {
                    return <CategoryResultItemLabelComponent item={item} scores={scores} key={index} />;
                  })}
                </div>
                <div className={classes.resultCategoryContainer}>
                  {resultCategoryList.map((item, index) => {
                    return <CategoryResultItemComponent item={item} scores={scores} key={index} />;
                  })}
                </div>
              </div>
              <div className={classes.buttonGroupContainer}>
                <div className={classes.buttonContainer}>
                  <Button
                    title={t(`eTriageResultPage.aboutTheQuestionnaireAndResult.${currentBu}`)}
                    radiusType={buttonRadiusTypes.rounder}
                    type={buttonTypes.outlined}
                    onClick={handleClickOpen()}
                  />
                </div>

                {env.isDisplayCopyResultLinkButton && (
                  <>
                    <div className={classes.buttonContainer}>
                      <TransitionButton
                        title={t(`eTriageResultPage.saveYourResult`)}
                        radiusType={buttonRadiusTypes.rounder}
                        onClick={() => saveResultLinkOnClick()}
                        postActionText={t(`eTriageResultPage.saveYourResultLinkCopied`)}
                      />
                    </div>
                    <div className={classes.buttonContainer}>
                      <AppIcon imageSrc={oneClickSvg} additionalClass={classes.onClickIcon} />
                      <div className={classes.saveResultInstruction}>{t(`eTriageResultPage.saveYourResultHint.${currentBu}`)}</div>
                    </div>{" "}
                  </>
                )}

                <div className={clsx(classes.buttonContainer, classes.takeTheTestAgainButton)} onClick={takeNewTest}>
                  <CommonIcon type={commonIconTypes.arrowRightOrange} size={16} />
                  <Spacer x={5} />
                  <div>{t(`eTriageResultPage.takeTheTestAgain.${currentBu}`)}</div>
                </div>
              </div>
            </div>
          </div>

          <div className={clsx([classes.resultAnalysis, shouldShowEmergency && classes.withEmergency])}>
            <div className={classes.resultAnalysisContentContainer}>
              <div className={clsx(classes.nextstepSection, shouldDisplaySuggestedProduct && classes.nextstepSectionWithSmallPadding)}>
                <div className={classes.nextstepCardContainer}>
                  <div className={classes.suggestedNextStep}>{t(`eTriageResultPage.suggestedNextStep.${currentBu}`)}</div>
                  <RenderNextStepOptions
                    optionList={shouldShowEmergency ? optionArray[1] : optionArray[getTreatmentSignposting(scores).label]}
                    {...(clientId && (clientId = { clientId }))}
                  />
                </div>
              </div>
            </div>
          </div>

          {shouldDisplaySuggestedProduct ? <RenderProductSuggestion customerType={customerType} /> : <div></div>}

          <Dialog open={open} onClose={handleClose} scroll={"paper"} aria-labelledby="scroll-dialog-title" aria-describedby="scroll-dialog-description">
            <DialogCloseButtonRow handleClose={handleClose} />
            <DialogTitle id="scroll-dialog-title">
              {t(`aboutTheQuestionnaireDetails.${currentBu}.title`, {
                ns: "resultPageLongText",
              })}
            </DialogTitle>
            <DialogContent dividers={scroll === "paper"}>
              <DialogContentText id="scroll-dialog-description" tabIndex={-1}>
                <div className={classes.dialogContent}>
                  {ReactHtmlParser(
                    t(`aboutTheQuestionnaireDetails.${currentBu}.detail`, {
                      ns: "resultPageLongText",
                    })
                  )}
                </div>
              </DialogContentText>
            </DialogContent>
          </Dialog>

          <Dialog
            open={openPoweredByDialog}
            onClose={handleClosePoweredByDialog}
            scroll={"paper"}
            aria-labelledby="scroll-dialog-title"
            aria-describedby="scroll-dialog-description">
            <DialogCloseButtonRow handleClose={handleClosePoweredByDialog} />
            <DialogContent dividers={scroll === "paper"}>
              <DialogContentText
                id="scroll-dialog-description"
                // ref={descriptionElementRef}
                tabIndex={-1}>
                <div className={classes.dialogContent}>{ReactHtmlParser(t(`poweredBySection.koaHealthIntro.${currentBu}`))}</div>
              </DialogContentText>
            </DialogContent>
          </Dialog>
        </div>
      )}
    </>
  );
}

export default withTranslation()(ResultPage);
