const RiskLevelOptionArray = {
  1: [
    { component: "DoctorAnywherePsychologistOption", recommended: false },
    { component: "SelfCareOption", recommended: false },
  ],
  2: [
    { component: "DoctorAnywherePsychologistOption", recommended: false },
    { component: "SimplifiedPsychiatristOption", recommended: false,  },
    { component: "EmergencyOption", recommended: false},
  ],
  3: [
    { component: "SelfCareOption", recommended: false },
    { component: "DoctorAnywhereCounsellingOption", recommended: false },
    { component: "EmergencyOption", recommended: false },
  ],
  4: [
    { component: "DoctorAnywherePsychologistOption", recommended: false },
    { component: "SelfCareOption", recommended: false },
    { component: "EmergencyOption", recommended: false },
  ],
  5: [
    { component: "DoctorAnywhereCounsellingOption", recommended: false },
    { component: "SelfCareOption", recommended: false },
    { component: "EmergencyOption", recommended: false },
  ],
  6: [
    { component: "DoctorAnywherePsychologistOption", recommended: false },
    { component: "SelfCareOption", recommended: false },
    { component: "SimplifiedPsychiatristOption", recommended: false },
  ],
};

export default RiskLevelOptionArray;
